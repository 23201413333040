<script setup>
import { useStore } from 'vuex'

const store = useStore()
</script>
<template>
	<Transition name="v-fade">
		<div
			v-if="store.getters['popup/getLoding']"
			class="vm-loding-wrap"
		>
			<div class="vm-loding-inner">
				<div class="vm-loding-text">LOADING</div>
			</div>
		</div>
	</Transition>
</template>