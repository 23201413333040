import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import users from '@/store/modules/users.js'
import popup from '@/store/modules/popup.js'

export default createStore({
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		users,
		popup,
	},
	plugins: [ 
		createPersistedState({
		storage: window.localStorage,
		// storage: window.sessionStorage,
			paths: [
				'users',
			],
		}), 
	]
})