<script setup>
import { useStore } from 'vuex'

const store = useStore()
</script>
<template>
	<Transition name="v-botttomTop">
		<div
			v-if="store.getters['popup/getToastPop'].show"
			class="vm-toast-popup"
			:class="
				store.getters['popup/getToastPop'].type ?
					store.getters['popup/getToastPop'].title ? 
						store.getters['popup/getToastPop'].type : 
						`${store.getters['popup/getToastPop'].type} title-off` :
					''
			"
		>
			<div
				class="vm-toast-popup-title"
				v-if="store.getters['popup/getToastPop'].title"
			>
				{{store.getters['popup/getToastPop'].title}}
			</div>
			<div class="vm-toast-popup-contents">{{store.getters['popup/getToastPop'].contents}}</div>
			<button @click="store.dispatch('popup/setToastShow', {show: false})"></button>
		</div>
	</Transition>
</template>