import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
	// 404
	{
		path: "/404",
		name: "Page404",
		component: () => import('@/views/warning/Page404.vue'),
		meta: {
			notCheckLogin: true,
		}
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
		meta: {
			notCheckLogin: true,
		}
	},
	//// 404

	// login
	{
		path: '/login',
		component: () => import('@/components/layout/VmFullLayout.vue'),
		children: [
			{
				path: '',
				name: 'Login',
				component: () => import('@/views/Login.vue'),
				meta: {
					notCheckLogin: true,
				}
			},
		],
	},
	//// login

	{
		path: '/',
		component: () => import('@/components/layout/VmAsideLayout.vue'),
		children: [

			{
				path: '',
				name: 'UserMain',
				component: () => import('@/views/main/Index.vue'),
			},

			{
				path: 'consulting/:userId',
				name: 'Consulting',
				component: () => import('@/views/consulting/Index.vue'),
				children: [
					{
						path: ':chatRoomId',
						name: 'ConsultingChatting',
						component: () => import('@/views/consulting/Chatting.vue'),
					},
				]
			},

			{
				path: 'guide',
				name: 'Guide',
				component: () => import('@/views/guide/Index.vue'),
			},

			{
				path: 'template',
				name: 'Template',
				component: () => import('@/views/template/Index.vue'),
			},
			{
				path: 'template/write/:boNo?',
				name: 'TemplateWrite',
				component: () => import('@/views/template/Write.vue'),
			},

			{
				path: 'question',
				name: 'Question',
				component: () => import('@/views/question/Index.vue'),
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router

router.beforeEach(async function(to, from, next) {
	// 로그인 체크가 필요한 곳에서 비로그인시 이동
	if(!to.meta.notCheckLogin){
		if(!store.getters['users/getToken']){
			next('/login');
		}
	}
	next()
});