const users = {
	namespaced: true,

	state: {
		TOKEN: '',
		INFO: null,
		CHEMIST_LIST: [],
		ROOM_LIST: [],
		ROOM_LIST_LAST: {},
	},

	getters: {
		getToken: state => {
			return state.TOKEN;
		},
		getInfo: state => {
			return state.INFO;
		},
		getChemistList: state => {
			state.CHEMIST_LIST.sort(function(a, b)  {
				return new Date(b.loginDt) - new Date(a.loginDt);
			});
			return state.CHEMIST_LIST;
		},
		getRoomList: state => {
			state.ROOM_LIST.sort(function(a, b)  {
				return new Date(b.lastMsgInfo.regDt) - new Date(a.lastMsgInfo.regDt);
			});
			let reData = state.ROOM_LIST.map(item => {
				if(item.lastMsgInfo.message){
					item.lastMsgInfo.message = item.lastMsgInfo.message.replaceAll(/(<a[^>]+>|<\/a>)/g, '')
				}
				return item;
			})
			return reData;
		},
		getRoomListLast: state => {
			return state.ROOM_LIST_LAST;
		}
	},

	mutations: {
		SET_LOGIN: (state, data) => {
			state.TOKEN = data.jwt;
			state.INFO = data;
		},
		SET_INFO: (state, data) => {
			state.INFO = data;
		},
		SET_CHEMIST_LIST: (state, data) => {
			state.CHEMIST_LIST = data;
		},
		SET_ROOM_LIST: (state, data) => {
			state.ROOM_LIST = data;
		},
		SET_ROOM_LIST_LAST: (state, data) => {
			state.ROOM_LIST_LAST = data;
		},
		SET_CLEAR: (state) => {
			state.TOKEN = '';
			state.INFO = null;
			state.CHEMIST_LIST = [];
			state.ROOM_LIST = [];
			state.ROOM_LIST_LAST = {};
		}
	},

	actions: {
		setLogin({ commit }, data) {
			commit('SET_LOGIN', data);
		},
		setInfo({ commit }, data) {
			commit('SET_INFO', data);
		},
		setChemistList({ commit }, data) {
			commit('SET_CHEMIST_LIST', data);
		},
		setRoomList({ commit }, data) {
			commit('SET_ROOM_LIST', data);
		},
		setRoomListLast({ commit }, data) {
			commit('SET_ROOM_LIST_LAST', data);
		},
		setLogout({ commit }) {
			commit('SET_CLEAR');
		},
	},
}

export default users