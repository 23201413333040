const popup = {
	namespaced: true,

	state: {
		LODING: false,
		TOASTPOP : {
			show: false,
			setIn: null,
			type: 'primary',
			title: undefined,
			contents: undefined
		},
	},

	getters: {
		getLoding: state => {
			return state.LODING;
		},
		getToastPop: state => {
			return state.TOASTPOP;
		},
	},

	mutations: {
		SET_LODING: (state, data) => {
			state.LODING = data;
		},
		SET_TOASTPOP: (state, data) => {
			state.TOASTPOP.show = data.show;
			if(data.show){
				state.TOASTPOP.type = data?.type || 'primary',
				state.TOASTPOP.title = data?.title || undefined,
				state.TOASTPOP.contents = data?.contents || 'error!!!'
			}
			clearTimeout(state.TOASTPOP.setIn)
			state.TOASTPOP.setIn = setTimeout(() => {
				state.TOASTPOP.show = false
			}, 1200);
		}
	},

	actions: {
		setLoding({ commit }, data) {
			commit('SET_LODING', data);
		},
		setToastShow({ commit }, data) {
			commit('SET_TOASTPOP', data);
		},
	},
}

export default popup